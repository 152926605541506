import React, { useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import "../../static/wow_book/wow_book.css"
import MobileHeader from "../containers/MobileHeader";
import GoBack from "../assets/svg/goback-line.inline.svg";
import Media from "react-media";
import SiteLogo from "../containers/SiteLogo"

import { css } from "@emotion/react";
import Close from "../assets/svg/close.inline.svg";

import { ModalRoutingContext } from "../context/modal-routing";

const $ = typeof window !== "undefined" ? window.jQuery : {};

const closeButton = css`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  display: flex;
  padding: 0;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
    fill: #3a383c;
  }
  @media (max-width: 768px) {
    padding: 10px;
    background-color: #fff;
    top: 0;
    right: 0;
  }
`;
const wowbookCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 56px;
  .wowbook-toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
const mobPdf = css`
  display: flex;
  flex-flow: column;
  a {
    height: 40px;
    border: none;
    svg {
      height: 40px;
      width: auto;
      max-width: 100%;
    }
  }
  iframe {
    width: 100%;
    height: calc(100vh - 123px);
    border: none;
  }
`;

const desktopPdf = css`
  display: flex;
  flex-flow: column;
  a {
    height: 130px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      height: 40px;
      width: auto;
      max-width: 100%;
    }
  }
  iframe {
    width: 100%;
    height: calc(100vh - 123px);
    border: none;
  }`;


const Pdf = ({ data }) => {
  // console.log(data);
  const { modal, closeTo } = useContext(ModalRoutingContext);

  useEffect(() => {
    if (modal) {

      $('#wowbook').wowBook({
        height: 500,
        width: 600,
        pdf: data.file.localURL,
        deepLinking: false,
        updateBrowserURL: false,
        scaleToFit: '#wowbook-container',
        toolbar: "first, back, next, last, zoomin, zoomout, slideshow, download",
        toolbarParent: '#wowbook-container',
        handleWidth: 120,
        curlSize: 100
      });
    } else {

    }
  }, []);
  return (
    <>
      {modal ? (
        <div id="wowbook-container" css={wowbookCss}>
          <div id="wowbook"></div>
          <Link
            to={closeTo}
            state={{ closingModal: true, scrollPosition: window.pageYOffset }}
            css={closeButton}
          >
            <Close />
          </Link>
        </div>
      ) : (
        <Media
          queries={{
            mobile: { maxWidth: 1365 },
          }}
          defaultMatches={{ mobile: true }}
        >
          {(matches) =>
            matches.mobile ? (
              <div css={mobPdf}>
                <MobileHeader />
                <Link to="/media-centre#resources"><GoBack /></Link>
                <iframe title="pdf-viewer"
                  src={`/pdfjs/web/viewer.html?file=${data.file.localURL}`} />
              </div>
            ) : (
              <div css={desktopPdf}>
                <SiteLogo
                  logos={{ normal: 'site_logo_dark' }}
                />
                <Link to="/media-centre#resources"><GoBack /></Link>
                <iframe title="pdf-viewer"
                  src={`/pdfjs/web/viewer.html?file=${data.file.localURL}`} />
              </div>
            )
          }
        </Media>
      )}
    </>
  )
}

export default Pdf;

export const query = graphql`query PdfTemplate($id: String!) {
  file(id: {eq: $id}) {
    name
    localURL
  }
}`;
